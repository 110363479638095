import type { Domain } from 'effector';
// import { attachLogger as attach } from 'effector-logger/attach';

export const attachLogger = async (domain: Domain) => {
  if(process.env.NODE_ENV === 'development' && typeof window !== 'undefined'){
    const logger = require('effector-logger');
    logger.attachLogger(domain);
  }

  // attach(domain, {
  //   reduxDevtools: 'disabled',
  //   inspector: 'disabled',
  //   console: 'enabled',
  // });
}
