import { createStore } from 'effector';
import { StorageAdapter, persist } from 'effector-storage';
import Cookies from "js-cookie";

export const cookiesAdapter =
  (expire: Date): StorageAdapter =>
  (key) => ({
    get: () => {
      const value = Cookies.get(key);
      if (!value) {
        return null;
      }
      return JSON.parse(value);
    },
    set: (value) => {
      Cookies.set(key, JSON.stringify(value), { expires: expire });
    },
  });

export const createCookiesStore = <T>(key: string, expire: Date) => {
  const store = createStore<T | null>(null, { name: key });
  persist({
    store,
    key,
    adapter: cookiesAdapter(expire),
  });
  return store;
};
